var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-item",on:{"click":_vm.showDetail}},[_c('p',{staticClass:"id"},[_vm._v("#"+_vm._s(_vm.invoiceItem.id))]),_c('p',{staticClass:"due"},[_vm._v(_vm._s(_vm.dateFormat(_vm.invoiceItem.invoice_date)))]),_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.invoiceItem.client_name))]),_c('p',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.invoiceItem.total))+" ")]),_c('div',{staticClass:"status",class:[
      _vm.invoiceItem.status === 'Draft'
        ? 'draft-background'
        : _vm.invoiceItem.status === 'Pending'
        ? 'pending-background'
        : 'paid-background' ]},[_c('div',{staticClass:"status-circle",class:[
        _vm.invoiceItem.status === 'Draft'
          ? 'circle-draft'
          : _vm.invoiceItem.status === 'Pending'
          ? 'circle-pending'
          : 'circle-paid' ]}),_c('div',{staticClass:"status-text",class:[
        _vm.invoiceItem.status === 'Draft'
          ? 'draft-color'
          : _vm.invoiceItem.status === 'Pending'
          ? 'pending-color'
          : 'paid-color' ]},[_vm._v(" "+_vm._s(_vm.invoiceItem.status)+" ")])]),_c('svg',{staticStyle:{"fill":"currentcolor","width":"10px","height":"10px"},attrs:{"color":"hsl(252, 94%, 67%)","viewBox":"0 0 1024 1024"}},[_c('path',{attrs:{"d":"M328.4 30l-144.8 144.8 337.2 337.2-337.2 337.2 144.8 144.8 482-482z"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }