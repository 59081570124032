<template>
  <div>
    <div class="invoices-header">
      <div class="title">
        <h1 class="title-name">Invoices</h1>
        <p class="title-total">Total Invoice: {{ $store.state.totalInvoice }}</p>
      </div>
      <div style="display:flex; align-items:center">
        <button class="download" @click="download()">
          <svg
            class="icon"
            style="width: 26px; height: 26px"
            viewBox="0 0 24 24"
          >
            <path fill="currentColor" :d="mdiFileDownload" />
          </svg>
        </button>
        <button class="add-invoice" @click="SET_MENU_IS_OPEN">
          Buat <span class="remove">Invoice</span><span class="symbol">+</span>
        </button>
      </div>
    </div>
    <div class="invoices-filter">
      <p>Filter:</p>
      <div class="filter" ref="filterStatus">
        <div @click="statusIsOpen = !statusIsOpen" class="filter-btn">
          Status
        </div>
        <div v-show="statusIsOpen" class="filter-body">
          <div class="filter-item">
            <input
              type="checkbox"
              id="draft"
              value="Draft"
              v-model="selectedFilter.status"
            />
            <label for="draft">Draft</label>
          </div>
          <div class="filter-item">
            <input
              type="checkbox"
              id="pending"
              value="Pending"
              v-model="selectedFilter.status"
            />
            <label for="pending">Pending</label>
          </div>
          <div class="filter-item">
            <input
              type="checkbox"
              id="paid"
              value="Paid"
              v-model="selectedFilter.status"
            />
            <label for="paid">Terbayar</label>
          </div>
        </div>
      </div>
      <div class="filter" ref="filterVendor">
        <div @click="vendorIsOpen = !vendorIsOpen" class="filter-btn">
          Vendor
        </div>
        <div v-show="vendorIsOpen" class="filter-body">
          <div v-for="(item, i) in vendor" :key="i" class="filter-item">
            <input
              type="checkbox"
              :id="item.nama"
              :value="item.nama"
              v-model="selectedFilter.vendor"
            />
            <label :for="item.nama">{{item.nama}}</label>
          </div>
        </div>
      </div>
      <div style="display:flex;align-items:center">
        <b class="remove">Tanggal: </b>
        <div class="input-item">
          <input
            v-model="selectedFilter.date.from"
            type="date"
          />
        </div>
        <div class="input-item">
          <input
            v-model="selectedFilter.date.to"
            type="date"
          />
        </div>
      </div>
    </div>
    <div class="invoices-count">
      <div v-if="omset" class="omset">
        <h3>Omset</h3>
        <div>{{currencyFormat(omset)}}</div>
      </div>
      <div v-if="profit" class="profit">
        <h3>Profit</h3>
        <div>{{currencyFormat(profit)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { mdiDownload } from "@mdi/js";
export default {
  name: "InvoicesHeader",
  data() {
    return {
      statusIsOpen: false,
      vendorIsOpen: false,
      selectedFilter: {},
      mdiFileDownload: mdiDownload,
    };
  },
  computed: {
    ...mapState(["filter"]),
  },
  props: ['omset', 'profit', 'vendor', 'store'],
  methods: {
    ...mapMutations(["SET_MENU_IS_OPEN", "SET_FILTER"]),
    closeFilterMenu(e) {
      if (this.statusIsOpen === true) {
        if (!this.$refs.filterStatus.contains(e.target)) {
          this.statusIsOpen = false;
        }
      }
      if (this.vendorIsOpen === true) {
        if (!this.$refs.filterVendor.contains(e.target)) {
          this.vendorIsOpen = false;
        }
      }
    },
    currencyFormat(v) {
      return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
      }).format(v)
    },
    download() {
      let baseUrl = (process.env.VUE_APP_API_URL ||
      `${window.location.protocol}//${window.location.hostname}:${window.location.port}`) + '/api';
      window.open(`${baseUrl}/invoice/export?filter=${encodeURIComponent(JSON.stringify(this.selectedFilter))}&store=${this.store}`)
    }
  },
  watch: {
    selectedFilter: {
      handler() {
        this.SET_FILTER(this.selectedFilter);
      },
      deep: true
    },
  },
  created() {
    window.addEventListener("click", this.closeFilterMenu);
    this.selectedFilter = {...this.filter};
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeFilterMenu);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.invoices-count {
  display: flex;
  gap: 15px;
  padding-right: 10px;
  color: white;
}
.omset {
  background-color: #7b5cfa;
}
.profit {
  color: rgb(51, 215, 160);
  background-color: rgba(51, 215, 160, 0.06);
}
.omset, .profit {
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}
.invoices-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 20px;
}
.title {
  flex-basis: 50%;
}
.title-name {
  font-weight: 700;
  font-size: clamp(1.25rem, 5vw, 2rem);
}
.title-total {
  font-weight: 500;
}
.btn-group {
  display: flex;
  align-items: center;
}
.invoices-filter {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 20px;
  gap: 15px;
}
.filter {
  position: relative;
}
.filter-btn {
  font-weight: 700;
  cursor: pointer;
}
.filter-body {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 20px;
  background-color: #252946;
  position: absolute;
  z-index: 99;
  top: 30px;
  left: -10px;
  border-radius: 8px;
  gap: 14px;
}
label {
  font-weight: 700;
  margin-left: 10px;
}
.filter-item {
  display: flex;
  align-items: center;
}
.download {
  margin-right: 10px;
  width: 45px;
  height: 45px;
  border: none;
  background-color: #4caf50;
  color: hsl(0, 0%, 100%);
  border-radius: 50px;
  outline: none;
  position: relative;
  cursor: pointer;
}
.add-invoice {
  font-weight: 700;
  padding: 16px 20px;
  border: none;
  background-color: #7b5cfa;
  color: hsl(0, 0%, 100%);
  border-radius: 24px;
  outline: none;
  position: relative;
  width: 160px;
  text-align: right;
  cursor: pointer;
}
.add-invoice::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 6px;
  width: 32px;
  height: 32px;
  background-color: hsl(0, 0%, 100%);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  transform: translateY(-50%);
}
.symbol {
  position: absolute;
  color: #7b5cfa;
  font-size: 20px;
  font-weight: 800;
  left: 16px;
  top: 13px;
}
.input-item {
  padding: 0 5px;
}

@media screen and (max-width: 1024px) {
  .invoices-filter {
    display: grid;
    grid-template-columns: 50px 50px 50px;
  }
  .filter {
    position: relative;
  }
  .filter-body {
    padding: 16px;
    gap: 8px;
  }
  label {
    margin-left: 8px;
  }
  .download {
    width: 33px;
    height: 33px;
  }
  .add-invoice {
    padding: 8px 14px;
    width: 80px;
  }
  .add-invoice::before {
    left: 6px;
    width: 20px;
    height: 20px;
  }
  .symbol {
    font-size: 16px;
    left: 11px;
    top: 8px;
  }
  .remove {
    display: none;
  }
}
</style>
