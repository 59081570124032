<template>
  <main class="home">
    <InvoicesHeader class="header" :vendor="vendor" :omset="currentData.revenue" :profit="currentData.profit" :store="store"/>
    <div class="invoices-container">
      <pulse-loader class="loader" color="#fff" v-if="isLoading" />
      <template v-else>
        <template v-if="currentData.total">
          <InvoiceShort
            v-for="(item, index) in data"
            :key="item.id"
            :invoiceItem="item"
            :index="index"
          />
          <b-pagination
            @change="(v) => getData(v, filter)"
            :total="currentData.total"
            :per-page="currentData.per_page"
            v-model="currentPage"
            range-before="2"
            range-after="2"
          >
          </b-pagination>
        </template>
        <template v-else>
          <div class="no-data">
            <h2>Tidak ada data</h2>
          </div>
        </template>
      </template>
    </div>
    <FormComp :store="store"/>
  </main>
</template>

<script>
import Axios from "axios";
import { mapMutations, mapState } from "vuex";
import InvoicesHeader from "./Header.vue";
import InvoiceShort from "./Short.vue";
import FormComp from "./Form.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "Home",
  props: {},
  components: {
    InvoicesHeader,
    InvoiceShort,
    PulseLoader,
    FormComp,
  },
  data() {
    return {
      data: [],
      currentPage: 1,
      currentData: {},
      isLoading: false,
      vendor: [],
      store: this.$route.query.store
    };
  },
  computed: {
    ...mapState(["filter", "refresh"]),
  },
  methods: {
    ...mapMutations(["SET_EDIT", "REFRESH_LIST", "SET_TOTAL_INVOICE", "SET_VENDOR_LIST", "SET_STORE_LIST"]),
    getData(page, filter) {
      this.SET_EDIT({ status: false });
      this.isLoading = true;
      this.data = [];
      this.currentData = {};
      let url = "/invoice?page=" + page;
      if (this.store) {
        url = `/invoice?page=${page}&store=${this.store}`;
      }
      Axios.get(url, {
        params: {
          filter: filter,
        },
      })
        .then((r) => {
          this.data = r.data.data;
          this.currentData = r.data;
          this.SET_TOTAL_INVOICE(this.currentData.total);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getVendor() {
      Axios.get("/vendor?all=true")
        .then((r) => {
          this.vendor = r.data;
          this.SET_VENDOR_LIST(this.vendor);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getStore() {
      Axios.get("/store?all=true")
        .then((r) => {
          this.SET_STORE_LIST(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setHeight() {
      let home = document.querySelector(".home");
      let header = document.querySelector(".home > .header");
      let elemHeight = header.offsetHeight;
      elemHeight += parseInt(window.getComputedStyle(header).getPropertyValue('margin-bottom'));
      document.querySelector(".invoices-container").style.height = `${home.clientHeight - elemHeight - 170}px`;
    }
  },
  watch: {
    filter: {
      handler(val) {
        this.currentPage = 1;
        this.getData((this.currentPage = 1), val);
      },
      deep: true
    },
    refresh(val) {
      if (val) {
        this.getData(1);
        this.REFRESH_LIST();
        this.currentPage = 1;
      }
    },
  },
  created() {
    this.getVendor();
    this.getStore();
  },
  mounted() {
    this.setHeight();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader {
  text-align: center;
  top: 50%;
  position: absolute;
  left: 50%;
}
.home {
  padding: 50px 150px 50px 220px;
  height: 100vh;
  width: 100%;
}
.no-data {
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.invoices-container {
  overflow-y: scroll;
  padding-right: 10px;
}
.header {
  margin-bottom: 30px;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #141624;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #252946;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  .home {
    padding: 100px 120px 20px 120px;
  }
  .header {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  .home {
    padding: 100px 20px 20px 20px;
  }
}
</style>
